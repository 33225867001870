import React, { Fragment, Component, useState} from 'react';
import Header from './common/header';
import Particle from './components/particle';
import UnauthedRoutes from './utilities/routes';
import Login from './components/login/login'

const App = () => {
  const[ isAuthed, setIsAuthed] = useState(false);
  const[ userObject, setUserObject] = useState(false);


  const handleLogin = (userObject) => {
    console.log("APP: Successful Login");
    setIsAuthed(true);
    setUserObject(userObject);
  }

  console.log("APP");

  return (
    <Fragment>

      <Particle/>

      {!isAuthed && 
        <Login handleLogin={handleLogin}/>
      }
      {isAuthed && 
        <Fragment> 
          <Header userObject={userObject} />
          <UnauthedRoutes/>   
        </Fragment>
      }
    </Fragment>
  );
};

export default App;
