import React, { Fragment, Component } from 'react';

// <ParticleBackround/>
// import ParticleBackround from '../common/particle-backround';


class Home extends Component{

  render(){
    console.log("index render:");


    return(
      <Fragment>
        
      </Fragment>


    );
  }

}
export default Home;
