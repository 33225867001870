import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export default function particle() {
    const particlesInit = async (main) => {
        console.log(main);
        await loadFull(main);
      };
    
      const particlesLoaded = (container) => {
        console.log(container);
      };

  return (
        <Particles
        id="tsparticles"
            init={particlesInit}
            Loaded={particlesLoaded}

            options={
                {
                    fullScreen: {
                        enable: true,
                        zIndex: -1,
                    }, 
                    background: {
                        color: "#000"
                      },
                      detectRetina: false,
                      fpsLimit: 30,

                      interactivity: {
                        detectsOn: "canvas",
                        events: {
                          resize: true
                        }
                      },

                      interactivity: {
                        events: {
                        onClick: { enable: true, mode: "push" },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                            parallax: { enable: false, force: 70, smooth: 50 }
                        },
                        resize: true
                        },
                        modes: {
                            push: { quantity: 25 },
                            repulse: { distance: 90, duration: 0.4 }
                            }
                        },
                      particles: {
                        color: {
                          value: "#fff"
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 1,
                            straight: false,
                        },
                        number: {
                          density: {
                            enable: true,
                            area: 1080
                          },
                          limit: 0,
                          value: 400
                        },
                        opacity: {
                          animation: {
                            enable: true,
                            minimumValue: 0.05,
                            speed: 0.25,
                            sync: false
                          },
                          random: {
                            enable: true,
                            minimumValue: 0.05
                          },
                          value: 1
                        },
                        shape: {
                          type: "circle"
                        },
                        size: {
                          random: {
                            enable: true,
                            minimumValue: 0.5
                          },
                          value: 1
                        }
                      }                          
            }
        }


        />
    )
}
