import React from 'react'
import { signInWithEmailAndPassword } from "firebase/auth";

import { authObject } from '../../index'


export const auth = (loginInput) => {
	console.log(loginInput);
	let loginResponse = {};

	return signInWithEmailAndPassword(authObject, loginInput.username, loginInput.password)
		.then((userCredential) => {
			// Signed in 
			console.log("successfull sign in")
			
			const user = userCredential.user;
			console.log(user);

			loginResponse['user'] = user; 
			loginResponse["result"] = true
			
			return(loginResponse);			
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;

			loginResponse['errorMessage'] = errorMessage; 
			loginResponse["result"] = false
			return loginResponse;
		});			
}

