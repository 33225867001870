
import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import styles from './header.css';

export default function Header(props){
  const {userObject} = props;

  const displayName = userObject?.displayName || "displayName";  

  console.log(displayName)  
  console.log("heading render");

  return(
    <Fragment>
      <div className="head">
        <div className='rightAl' > <Link  to="/" className="link"> {displayName} </Link> </div>

        <div className="headertext">
          <h1 className="htext"> Netwhere <span className="small">
          <br/>
          This is an emotional rollercoaster. We should do this more often</span>
          </h1>
        </div>

        <nav>
        
          <div className="navBarParent" id="NavBar" role="navigation" >
            


            <div className="center">
          
              <Link  to="/" className="link"> Home </Link>
              <Link  to="/audio" className="link"> Audio </Link>
              <Link  to="/archive" className="link"> Archive </Link>
              <Link  to="/login" className="link"> Login </Link> 
              <Link  to="/spotify" className="link"> Spotify </Link>                
              
            </div>
            
            


          </div>
        </nav>
      </div>
    </Fragment>
  );
};

// /*/*
// <!DOCTYPE html>
// <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
//
//
//
//
// <style media="screen">
//
// .head{
//   z-index: 9999;
//
// }
//
// .rightAl .icon {
//   display: none;
// }
//
// /* TOP TEXT HEADER */
// .headertext{
//   height: 150pt;
//   width: 100%;
//   display:table;
//   background-color: black;
//   /* background-image:url(resources/back.gif); */
// }
//
// .htext{
//   color: white;
//   position: relative;
//   text-align: center;
//   vertical-align:middle;
//   display:table-cell;
// }
//
// .small{
//   font-size: 12pt;
// }
//
//
// /* NAV BAR */
// .navBarParent{
//   line-height: 20px;
//   text-align: center;
//   height: 40px;
//   background-color: black;
// }
//
// .link{
//   margin: 25px;
//   color: white;
//   text-decoration: none;
//   background-color: none;
//   /* font-size: 1.5vw; */
// }
//
// .navBarParent a:hover {
//   color: purple;
// }
//
// .leftAl{
//   display: inline;
//   float: left;
//   margin-left: 2.0px;
// }
//
// .rightAl{
//   display: inline;
//   float: right;
//   margin-right: 2.0px;
//   /* position: relative */
// }
//
// .center{
//   display: inline;
// }
//
// .rightAl a{
//   color: white;
// }
//
// .socialMedia{
//   display: block;
//   position: absolute;
//   right: 0;
// }
//
// .social{
//   width: 15%;
// }
//
// @media screen and (max-width: 600px) {
//   .center a:not(:first-child) {display: none;}
//   .navBarParent a.icon {
//     float: right;
//     display: block;
//     margin-right: 5.0px;
//   }
//
//   .navBarParent{
//     text-align:left;
//   }
//
//   .rightAl a:not(:nth-child(2)) {
//     display: none;
//   }
//
//   .leftAl a{
//     display: none;
//   }
// }
//
//
// @media screen and (max-width: 600px) {
//   .navBarParent.responsive {
//     position: relative;
//     padding-bottom: 1pt;
//     height: auto;
//   }
//   .navBarParent.responsive a.icon {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
//   .navBarParent.responsive a {
//     margin: 0px;
//     padding: 25px;
//     float: none;
//     display: block;
//     text-align: left;
//     margin-top: 0px;
//   }
//
//   .navBarParent.responsive a.link:nth-child(even){
//     background-color: SteelBlue;
//   }
//
//   #leftLink a.link{
//     background-color: SteelBlue;
//   }
//
//
// }
// </style>
//
// <script type="text/javascript">
//
// // IF the drop down tab is left open when window is resized, this catches that and fixes styling
// window.addEventListener("resize", function() {
//   if (window.matchMedia("(max-width: 600px)").matches) {
//     var x = document.getElementById("NavBar");
//     var leftAl = document.getElementById("leftLink");
//     var rightAl = document.getElementById("rightLink");
//
//     x.className = "navBarParent";
//     leftAl.className = "leftAl";
//     rightAl.className = "rightAl";
//   }
// })
//
// // Function to display proper styling for dropdown menu on click
// function displayBar() {
//   var x = document.getElementById("NavBar");
//   var leftAl = document.getElementById("leftLink");
//   var rightAl = document.getElementById("rightLink");
//
//   if (x.className === "navBarParent") {
//     x.className += " responsive";
//
//     leftAl.classList.toggle("leftAl");
//     rightAl.classList.toggle("rightAl");
//   }
//   else {
//     x.className = "navBarParent";
//     leftAl.classList.toggle("leftAl");
//     rightAl.classList.toggle("rightAl");
//   }
// }
//
// </script>*/*/
