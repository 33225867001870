import React from 'react'
import { Route, Routes } from 'react-router-dom';

import Home from '../pages/index';
import Login from '../components/login/login';
import Audio from '../pages/audio/index'
import Spotify from '../pages/spotify'

function UnauthedRoutes() {
  return (
    <Routes>
        <Route path="/*" element={<Home/ >}/>
        <Route path="/audio/" element={<Audio/ >}/>        
        <Route path="/login/" element={<Login/ >}/>        
        <Route path="/spotify/" element={<Spotify/ >}/>        
      </Routes>
  )
}

export default UnauthedRoutes