import React, {useState, useRef } from 'react';

import styles from '../../common/login.css';

import {auth} from './auth'

export default function Login(props) {  
  const {handleLogin} = props;

  const [message, setMessage] = useState(" ")
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    const loginInput = { username: username, password: password };

    const loginResponse = await auth(loginInput);
    console.log(loginResponse);

    if(!loginResponse.result){      
      setMessage("username or password is incorrect");      
    }
    else if(loginResponse.result && loginResponse.user){
      setMessage("Login Successful")
      handleLogin(loginResponse.user);
    }    
  }
  
  
  return (
    <div className="formWrap" role="main">

      <form onSubmit={handleSubmit}>
          
        <div className="container">
          <h2> Netwhere </h2>
          <p> {message} </p>
        </div>

        <div className={styles.container} style={{ backgroundColor: "Gainsboro"}}>      

          <input 
            ref={usernameRef}
            type="text" 
            name="username"
            placeholder="Username"
            required
          />

          <input 
            ref={passwordRef}
            type="password"
            placeholder="Password"
            name="password"
            required
          />

        </div>

        <div className={styles.container} >
          <input 
            type="submit"
            name="submit"
          />

        </div>

      </form>

    </div>
    
  )
}
