import { createRoot } from 'react-dom/client';

import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


// tod0: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLKZZiuVvKDrmtVTQvwKezFaPPDm8O6SM",
  authDomain: "netwhere-7cd28.firebaseapp.com",
  projectId: "netwhere-7cd28",
  storageBucket: "netwhere-7cd28.appspot.com",
  messagingSenderId: "888022870608",
  appId: "1:888022870608:web:a8df6527706a8e11427f55"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const authObject = getAuth(firebaseApp);

// signInWithEmailAndPassword(authObject, "josephbsaba34@gmail.com", "ab1234")
// .then((userCredential) => {
//   // Signed in 
//   const user = userCredential.user;
//   console.log("successfull sign in")
//   console.log(user);
//   // ...
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     console.log(errorCode)

//     const errorMessage = error.message;
//     console.log(errorMessage);
//   });

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>
);

 

// document.getElementById('root'),
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
